import React, { useState, useEffect, useRef } from "react";
import Recents from "./Cards/recents.js";
import HeadtoHead from "./Cards/headtohead.js";
import Score from "./Cards/score.js";
import Versus from "./Cards/versus.js";
import Totals from "./Cards/totals.js";
import PointDiff from "./Cards/pointdiff.js";
import Overs from "./Cards/overs.js";
import Wins from "./Cards/wins.js";
import Spreads from "./Cards/spreads.js";
import Projections from "./Cards/projections.js";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Comments from "./Cards/comments.js";
import Unlock from "./Cards/unlock.js";
import OddsTable from "./OddsTable.js";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";
import { useParams, useLocation } from "react-router-dom";
import createScrollSnap from "scroll-snap";
import { useNavigate } from 'react-router-dom';

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={{...styles.cardbutton, width: '100%'}}
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', margin: 0 }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);

const Games = ({ isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fixture, setFixture] = useState(location.state?.fixture);
  const [isDataVisible, setDataVisible] = useState(false);
  const [isRecentsHomeVisible, setRecentsHomeVisible] = useState(false);
  const [isRecentsAwayVisible, setRecentsAwayVisible] = useState(false);
  const [isHeadtoHeadVisible, setHeadtoHeadVisible] = useState(false);
  const [isPowerRatingsVisible, setPowerRatingsVisible] = useState(false);
  const [isButtonListVisible, setButtonListVisible] = useState(true);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);


  const { isTutorialMode, userTier, userProfile } = useAppState();
  const containerRef = useRef(null);

  const isAuthenticatedAndPro = isAuthenticated && userTier === 'Pro';
  const isAuthenticatedAndFree = isAuthenticated && userTier === 'Free';

  const unlockedFixtures = userProfile.unlocked_fixtures || null

  const isFixtureUnlocked = unlockedFixtures ? unlockedFixtures.includes(fixture.id) : false;


  const toggleDataVisibility = () => {
    setDataVisible(!isDataVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleRecentsHomeVisibility = () => {
    setRecentsHomeVisible(!isRecentsHomeVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleRecentsAwayVisibility = () => {
    setRecentsAwayVisible(!isRecentsAwayVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleHeadtoHeadVisibility = () => {
    setHeadtoHeadVisible(!isHeadtoHeadVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const togglePowerRatingsVisibility = () => {
    setPowerRatingsVisible(!isPowerRatingsVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const resetAll = () => {
    console.log('Resetting all');
    setDataVisible(false);
    setRecentsHomeVisible(false);
    setRecentsAwayVisible(false);
    setHeadtoHeadVisible(false);
    setPowerRatingsVisible(false);
    setButtonListVisible(true);
  };

  const handleBackToGames = () => {
    const gameDate = new Date(fixture.game_time);
    navigate(`/${fixture.league.toLowerCase()}`, {
      state: { selectedDate: gameDate }
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();
      return () => unbind();
    }
  }, []);

  return (
    <div>
      <Card className="main" style={styles.main}>
        <Card.Body>
          <Card.Title>
            <b>
              <Versus fixture={fixture} /> at {convertUTCDateToLocalDateTime(fixture.game_time)}
            </b>
          </Card.Title>
          <OddsTable fixture={fixture} onClick={resetAll} />


          <Card.Text>
            {isButtonListVisible && (
              <CardButton 
                title="Projections"
                onClick={togglePowerRatingsVisibility}
              />
            )}
            {isPowerRatingsVisible && isAuthenticatedAndPro && (
              <Projections fixture={fixture} onClose={togglePowerRatingsVisibility} />
            )}
            {isPowerRatingsVisible && isAuthenticatedAndFree && (
              <>
                {isFixtureUnlocked ? (
                  <Projections fixture={fixture} onClose={togglePowerRatingsVisibility} />
                ) : (
                  <Unlock
                    fixture={fixture}
                    onClose={togglePowerRatingsVisibility}
                  />
                )}
              </>
            )}
            {isButtonListVisible && !isDataVisible && (
              <CardButton 
                title="Stats"
                onClick={toggleDataVisibility}
              />
            )}

            {isDataVisible && (
              <div ref={containerRef} style={{
                overflowY: "scroll",
                height: "100vh",
                scrollSnapType: "y mandatory",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}>
                  <section style={{ ...styles.sectionStyle, minHeight: "110vh" }}>
                    <Wins fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Spreads fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <PointDiff fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Totals fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Overs fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    {isTutorialMode && (
                      <ul style={{
                        fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                        textAlign: "left",
                        listStyleType: "none",
                        paddingLeft: "0",
                      }}>
                        <li>• You've reached the end of the main stats section. Continue scrolling to see recent games, head-to-head, projections, and comments.</li>
                        <li>• More info:
                          <ul style={{
                            paddingLeft: "20px",
                            listStyleType: "none",
                          }}>
                            <li>◦ The recent games section shows the last 10 games for each team with the elo ratings before each game</li>
                            <li>◦ The head-to-head section shows up to the last 10 matches between the two current</li>
                            <li>◦ The projections section shows our models' recommended plays and win probabilities. Check this after making your decisions to see how it lines up with our own picks. Or start with the recommended picks and work backwards. This is how we do it in our office</li>
                          </ul>
                        </li>
                      </ul>
                    )}
                    <Card className="cardbutton" style={{...styles.cardbutton, width: '100%'}} onClick={toggleDataVisibility}>
                      <Card.Text>Close</Card.Text>
                    </Card>
                  </section>
                </div>
              </div>
            )}

            {isButtonListVisible && (
              <CardButton 
                title={`${fixture.home_team} Recent Games`}
                onClick={toggleRecentsHomeVisibility}
              />
            )}

            {isRecentsHomeVisible && (
              <>
                <Recents name={fixture.home_team} date={fixture.game_time} />
                <CardButton 
                  title="Close Recent Games"
                  onClick={toggleRecentsHomeVisibility}
                />
              </>
            )}

            {isButtonListVisible && (
                          <CardButton 
                            title={`${fixture.away_team} Recent Games`}
                            onClick={toggleRecentsAwayVisibility}
                          />
                        )}

            {isRecentsAwayVisible && (
              <>
                <Recents name={fixture.away_team} date={fixture.game_time} />
                <CardButton 
                  title="Close Recent Games"
                  onClick={toggleRecentsAwayVisibility}
                />
              </>
            )}

            {isButtonListVisible && (
              <CardButton 
                title="Head to Head"
                onClick={toggleHeadtoHeadVisibility}
              />
            )}

            {isHeadtoHeadVisible && (
              <HeadtoHead team1={fixture.home_team} team2={fixture.away_team} game={fixture} onClose={toggleHeadtoHeadVisibility} />
            )}

            {isButtonListVisible && (
              <CardButton 
                title="Comments"
                onClick={() => setIsCommentsOpen(true)}
              />
            )}

            {isButtonListVisible && 
              <Comments 
                fixture={fixture} 
                isAuthenticated={isAuthenticated}
                isOpen={isCommentsOpen}
                toggle={() => setIsCommentsOpen(!isCommentsOpen)}
              />
            }

            <CardButton 
              title="Back"
              onClick={handleBackToGames}
            />
            
            <Spacer size={32} />
            <Score fixture={fixture} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const Spacer = ({ size }) => <div style={{ height: size }} />;

export default Games;