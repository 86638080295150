import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Card, CardBody, CardText } from 'reactstrap';
import { styles } from "../../styles/styles.js";
import moment from 'moment'; 

const Comments = ({ fixture, isAuthenticated, isOpen, toggle }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/comments/?fixture=${fixture.id}`);
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async () => {
    if (!showInput) {
      setShowInput(true);
    } else if (newComment.trim()) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/comments/`, {
          fixture: fixture.id,
          text: newComment,
          parent: replyingTo ? replyingTo.id : null
        }, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          }
        });
        setComments([...comments, response.data]);
        setNewComment('');
        setShowInput(false);
        setReplyingTo(null);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const modalStyle = {
    ...styles.comment,
    width: '100%',
  };

  const formatTimeSince = (dateString) => {
    return moment(dateString).fromNow();
  };

  const handleReplyClick = (comment) => {
    setReplyingTo(comment);
    setShowInput(true);
    setNewComment('');
  };

  const renderCommentHeader = (comment) => {
    return (
      <div style={styles.commentHeader}>
        <span style={styles.username}>{comment.user}</span>
        <span style={styles.timestamp}>{formatTimeSince(comment.created_at)}</span>
              {/* Add spaces or a tab here */}
        <span style={{marginLeft: '20px'}}>   </span>
        <span 
          style={{...styles.replyButton, cursor: 'pointer'}} 
          onClick={() => handleReplyClick(comment)}
        >
            reply
        </span>
      </div>
    );
  };

  const renderComments = (parentComment = null, depth = 0) => {
    const filteredComments = comments.filter(comment => 
      parentComment ? comment.parent === parentComment.id : !comment.parent
    );

    return filteredComments.map(comment => (
      <div key={comment.id} style={{marginLeft: `${depth * 20}px`}}>
        <Card style={styles.commentCard}>
          <CardBody>
            {renderCommentHeader(comment)}
            <CardText>{comment.text}</CardText>
          </CardBody>
        </Card>
        {renderComments(comment, depth + 1)}
      </div>
    ));
  };

  return isAuthenticated ? (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} style={{ modalStyle, maxHeight: '70vh', overflow: 'auto', color: 'black' }} size="lg" scrollable>
        <ModalHeader toggle={toggle} style={{ modalStyle, maxHeight: '70vh', overflow: 'auto', color: 'white', backgroundColor: 'black' }}>Comments</ModalHeader>
        <ModalBody style={{ ...modalStyle, maxHeight: '70vh', overflow: 'auto' }}>
          {renderComments()}
        </ModalBody>
        <ModalFooter style={modalStyle}>
          {showInput ? (
            <Input
              type="textarea"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder={replyingTo ? `Reply to ${replyingTo.user}...` : "Add a comment..."}
              style={{ ...styles.comment, width: '100%', marginBottom: '10px', fontSize: '16px' }}
            />
          ) : null}
          <Button color="white" onClick={handleAddComment} style={{ ...modalStyle, cursor: 'pointer' }}>
            {showInput ? (replyingTo ? `Reply to ${replyingTo.user}` : 'Post') : 'Add Comment'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : null;
};

export default Comments;