import React from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../styles/styles.js";

const OddsTable = ({ fixture, selectedTeam, onClick }) => {
    const shouldHighlightHomeSpread = () => {
        let teamPoints, opponentPoints, spread;
        if (selectedTeam === fixture.home_team) {
            teamPoints = fixture.home_points;
            opponentPoints = fixture.away_points;
            spread = fixture.home_spread;
        } else {
            return false
        }

        return teamPoints + spread >= opponentPoints;
    };

  const shouldHighlightHomeML = () => {
    let teamPoints, opponentPoints;
    if (selectedTeam === fixture.home_team) {
      teamPoints = fixture.home_points;
      opponentPoints = fixture.away_points;
    }
    else {
        return false
    }

    return teamPoints >= opponentPoints;
  };

  const shouldHighlightOver = () => {
    return fixture.home_points + fixture.away_points >= fixture.over;
  };

  const shouldHighlightAwaySpread = () => {
    let teamPoints, opponentPoints, spread;
    
    if (selectedTeam === fixture.away_team) {
      teamPoints = fixture.away_points;
      opponentPoints = fixture.home_points;
      spread = fixture.away_spread;
    }
    else 
    {
      return false
    }

    return teamPoints + spread >= opponentPoints;
  };

  const shouldHighlightAwayML = () => {
    let teamPoints, opponentPoints;
    if (selectedTeam === fixture.away_team) {
      teamPoints = fixture.away_points;
      opponentPoints = fixture.home_points;
    }
    else {
      return false
    }

    return teamPoints >= opponentPoints;
  };

  const shouldHighlightUnder = () => {
    return  (fixture.winner) && (fixture.home_points + fixture.away_points <= fixture.under);
  };

  return (
    <Table className="main" bordered onClick={onClick}>
      <tbody>
        <tr>
          <td className="Odds" style={styles.Odds}> Home </td>
          <td className="Odds" style={styles.Odds}> {fixture.home_team} </td>
          <td className="Odds" style={shouldHighlightHomeSpread() ? styles.Suggested : styles.Odds}>
            {fixture.home_spread} ({fixture.home_spread_price})
          </td>
          <td className="Odds" style={shouldHighlightHomeML() ? styles.Suggested : styles.Odds}>
            {fixture.home_moneyline}
          </td>
          <td className="Odds" style={shouldHighlightOver() ? styles.Suggested : styles.Odds}>
            {fixture.over} ({fixture.over_price})
          </td>
        </tr>
        <tr>
          <td className="Odds" style={styles.Odds}> Away </td>
          <td className="Odds" style={styles.Odds}> {fixture.away_team} </td>
          <td className="Odds" style={shouldHighlightAwaySpread() ? styles.Suggested : styles.Odds}>
            {fixture.away_spread} ({fixture.away_spread_price})
          </td>
          <td className="Odds" style={shouldHighlightAwayML() ? styles.Suggested : styles.Odds}>
            {fixture.away_moneyline}
          </td>
          <td className="Odds" style={shouldHighlightUnder() ? styles.Suggested : styles.Odds}>
            {fixture.under} ({fixture.under_price})
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OddsTable;