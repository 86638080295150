import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { styles } from '../styles/styles.js';
import createScrollSnap from 'scroll-snap';
import { useAppState } from '../AppStateContext.js';

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={styles.cardbutton_solo}
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', margin: 0 }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [showProfiles, setShowProfiles] = useState(false);
  const [showTierUpdate, setShowTierUpdate] = useState(false);
  const [selectedTier, setSelectedTier] = useState('');
  const [profileId, setProfileId] = useState('');
  const [tierCounts, setTierCounts] = useState(null);
  const { userProfile, fetchUserProfile } = useAppState();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container && showProfiles) {
      const { bind, unbind } = createScrollSnap(container, {
        snapDestinationY: '0%',
        timeout: 100,
        duration: 300,
        threshold: 0.2,
        snapStop: false,
        easing: (t) => t * (2 - t),
      });

      bind();
      return () => unbind();
    }
  }, [showProfiles]);

  useEffect(() => {
    fetchTierCounts();
  }, []);

  const fetchTierCounts = async () => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      const { data } = await axios.get(`${url}/dashboard/tier_counts/`, {
        headers: { Authorization: `Token ${token}` }
      });
      setTierCounts(data);
    } catch (error) {
      console.error('Error fetching tier counts:', error);
    }
  };

  

  const fetchProfiles = async () => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      const { data } = await axios.get(`${url}/dashboard/profiles/?limit=100`, {
        headers: { Authorization: `Token ${token}` }
      });
      setProfiles(data);
      setShowProfiles(true);
      setShowTierUpdate(false);
    } catch (error) {
      console.error('Error fetching profiles:', error);
    }
  };

  const handleUpdateTier = async (e) => {
    e.preventDefault();
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      await axios.put(`${url}/dashboard/${profileId}/update_tier/`, 
        { tier: selectedTier },
        { headers: { Authorization: `Token ${token}` }}
      );
  
      // Check if the updated profile is the current user's profile
      if (userProfile && userProfile.id === parseInt(profileId)) {
        console.log('Updating user profile in context');
        await fetchUserProfile(); // This will update the tier in localStorage and context
      }
  
      alert('Tier updated successfully');
    } catch (error) {
      console.error('Error updating tier:', error);
      alert('Error updating tier');
    }
  };

  const toggleProfiles = () => {
    if (!showProfiles) {
      fetchProfiles();
    } else {
      setShowProfiles(false);
    }
  };

  const toggleTierUpdate = () => {
    setShowTierUpdate(!showTierUpdate);
    setShowProfiles(false);
  };

  const handleUpgrade = async (profileId) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      await axios.post(`${url}/dashboard/upgrade/`, 
        { id: profileId },
        { headers: { Authorization: `Token ${token}` }}
      );
      // Refresh profiles instead of manual state update
      await fetchProfiles();
      // Refresh tier counts if needed
      await fetchTierCounts();
    } catch (error) {
      console.error('Error upgrading subscription:', error);
    }
  };

  const handleDowngrade = async (profileId) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      await axios.post(`${url}/dashboard/downgrade/`, 
        { id: profileId },
        { headers: { Authorization: `Token ${token}` }}
      );
      await fetchProfiles();
      await fetchTierCounts();
    } catch (error) {
      console.error('Error downgrading subscription:', error);
    }
  };

  const handleDelete = async (profileId) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      await axios.post(`${url}/dashboard/delete_user/`, {
        id: profileId,
      }, {
        headers: { Authorization: `Token ${token}` }
      });
      await fetchProfiles();
      await fetchTierCounts();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderSubscriptionAction = (profile) => {
    const actionStyle = {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#007bff'  
    };
  
    return (
      <>
        {profile.subscriptions?.tier === 'Free' && (
          <p 
            style={actionStyle}
            onClick={() => handleUpgrade(profile.id)}
          >
            Upgrade subscription
          </p>
        )}
        {profile.subscriptions?.tier === 'Pro' && (
          <p 
            style={actionStyle}
            onClick={() => handleDowngrade(profile.id)}
          >
            Downgrade subscription
          </p>
        )}
        <p 
          style={{...actionStyle, color: '#dc3545'}}
          onClick={() => handleDelete(profile.id)}
        >
          Delete user
        </p>
      </>
    );
  };

  const renderProfile = (profile) => (
    <div 
      key={profile.id}
      style={{
        scrollSnapAlign: 'start',
        minHeight: '100px',
        padding: '10px',
        borderBottom: '1px solid #444'
      }}
    >
      <p>Username: {profile.user}</p>
      <p>ID: {profile.id}</p>
      <p>Tier: {profile.subscriptions?.tier}</p>
      {renderSubscriptionAction(profile)}
    </div>
  );

  return (
    <div>
      <h2 style={{ color: 'white', marginBottom: '20px', marginLeft: '1rem' }}>Dashboard</h2>

      {tierCounts && (
      <div className="mb-4">
        <h3 style={{ color: 'white', marginBottom: '20px', marginLeft: '1rem' }}>User Tiers Summary</h3>
        <ul style={{ color: 'white', listStyleType: 'none', padding: 0, marginLeft: '1rem' }}>
          <li>Pro Users: {tierCounts.Pro || 0}</li>
          <li>Free Users: {tierCounts.Free || 0}</li>
          <li>Anonymous Users: {tierCounts.Anon || 0}</li>
        </ul>
      </div>
    )}

      <CardButton 
        title="View User Profiles"
        onClick={toggleProfiles}
      />

      {showProfiles && (
        <div
          ref={containerRef}
          style={{
            overflowY: 'scroll',
            height: '70vh',
            scrollSnapType: 'y mandatory',
          }}
        >
      <div style={{ color: 'white', padding: '1rem' }}>
        {profiles.map(renderProfile)}
      </div>
        </div>
      )}

      <CardButton 
        title="Update User Tier"
        onClick={toggleTierUpdate}
      />

      {showTierUpdate && (
        <Form onSubmit={handleUpdateTier} style={{ padding: '1rem', color: 'white' }}>
          <Form.Group className="mb-3">
            <Form.Label>Profile ID</Form.Label>
            <Form.Control 
              type="text" 
              value={profileId}
              onChange={(e) => setProfileId(e.target.value)}
              placeholder="Enter Profile ID"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              label="Pro"
              name="tier"
              value="Pro"
              checked={selectedTier === 'Pro'}
              onChange={(e) => setSelectedTier(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Free"
              name="tier"
              value="Free"
              checked={selectedTier === 'Free'}
              onChange={(e) => setSelectedTier(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Anon"
              name="tier"
              value="Anon"
              checked={selectedTier === 'Anon'}
              onChange={(e) => setSelectedTier(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Tier
          </Button>
        </Form>
      )}
    </div>
  );
};

export default Dashboard;