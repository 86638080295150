import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Game from '../components/game.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useAppState } from '../AppStateContext';


import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { styles } from '../styles/styles';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={styles.cardbutton_half}
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', textAlign: 'center' }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);


const ListOfGames = ({ league, selectedDate, setSelectedDate }) => {
  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState({});
  const { isAuthenticated, getWithExpiry, setWithExpiry, userProfile } = useAppState();

  const handleDateChange = (direction) => {
    const baseDate = selectedDate || new Date();
    const newDate = new Date(baseDate);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 1 : -1));
    setSelectedDate(newDate);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileID = userProfile?.id || null;
      
      var currentDate;

      if (!selectedDate) {
        currentDate = null
      }
      else {
        currentDate = selectedDate.toISOString().split('T')[0]
      }
      
      // Create cache key based on league and date
      const cacheKey = `fixtures_${league}_${profileID}_${selectedDate}`;
      console.log("cacheKey:", cacheKey)
      
      // Check cache first
      const cachedData = getWithExpiry(cacheKey);
      if (cachedData) {
        setGroupedData(cachedData);
        setLoading(false);
        console.log("Cache hit")
        return;
      }

      console.log("Cache miss")
      try {
        const url = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('authToken');
        const headers = token ? { Authorization: `Token ${token}` } : {};
        
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params: { league: league, date: selectedDate },
          headers: headers
        });

        // Group fixtures by exact game time
        const grouped = response.reduce((acc, fixture) => {
          const gameTime = formatTime(fixture.game_time);
          
          if (!acc[gameTime]) {
            acc[gameTime] = [];
          }
          acc[gameTime].push(fixture);
          return acc;
        }, {});

        // Sort fixtures within each group by absolute value of home_spread
        Object.keys(grouped).forEach(key => {
          grouped[key].sort((a, b) => {
            const spreadA = Math.abs(parseFloat(a.home_spread));
            const spreadB = Math.abs(parseFloat(b.home_spread));
            return spreadA - spreadB;
          });
        });

        // Sort the game times
        const sortedGroupedData = Object.keys(grouped)
          .sort((a, b) => new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b))
          .reduce((acc, key) => {
            acc[key] = grouped[key];
            return acc;
          }, {});

        setGroupedData(sortedGroupedData);
        console.log("Got response at " + new Date().toLocaleTimeString());
        // Store in cache
        setWithExpiry(cacheKey, sortedGroupedData);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [league, selectedDate, getWithExpiry, setWithExpiry]);

  return (
    <Container>
      <Col>
        {loading ? (
          <p>Loading...</p>
        ) :  Object.keys(groupedData).length === 0 ? (
          <div className="text-center">
            <p style={{ color: 'white', marginTop: '20px', fontSize: 'clamp(10px, calc(8px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)' }}>No games scheduled for {selectedDate ? selectedDate.toLocaleDateString() : 'today'}</p>
            <Row className="mx-0">
              <Col xs={6} className="px-2">
                <CardButton 
                  title="Previous Day"
                  onClick={() => handleDateChange('prev')}
                />
              </Col>
              <Col xs={6} className="px-2">
                <CardButton 
                  title="Next Day"
                  onClick={() => handleDateChange('next')}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <>
            {Object.entries(groupedData).map(([gameTime, fixtures]) => (
              <React.Fragment key={gameTime}>
                <h3 style={{ color: 'white', marginTop: '20px' }}>{gameTime}</h3>
                <hr style={{ borderTop: '2px solid white', margin: '10px 0' }} />
                {fixtures.map((fixture) => (
                  <Game
                    key={fixture.id}
                    fixture={fixture}
                    isAuthenticated={isAuthenticated}
                  />
                ))}
              </React.Fragment>
            ))}
            <Row className="mx-0 mt-4">
              <Col xs={6} className="px-2">
                <CardButton 
                  title="Previous Day"
                  onClick={() => handleDateChange('prev')}
                />
              </Col>
              <Col xs={6} className="px-2">
                <CardButton 
                  title="Next Day"
                  onClick={() => handleDateChange('next')}
                />
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Container>
  );
};

export default ListOfGames;