import React, { useEffect, useState, useRef } from "react"; // Add useRef
import axios from "axios";
import Winner from "./winner.js";
import OddsTable from "../OddsTable.js";
import createScrollSnap from "scroll-snap"; // Add this import

export function Recents({ name, date }) {
  const [data, setData] = useState([]);
  const containerRef = useRef(null); // Add container ref

  useEffect(() => {
    // Existing data fetch effect
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_URL;
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params: {
            team: name,
            date: date,
          },
        });
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [name, date]);

  // Add scroll snap effect
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();
      return () => unbind();
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        overflowY: "scroll",
        height: "100vh",
        scrollSnapType: "y mandatory",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {data.map((fixture) => (
          <section
            key={fixture.id} 
            style={{
              minHeight: "100vh",
              scrollSnapAlign: "start",
              marginBottom: "5vh",
            }}
          >
            <Winner fixture={fixture} name={name} />
            <OddsTable fixture={fixture} selectedTeam={name} />
          </section>
        ))}
      </div>
    </div>
  );
}

export default Recents;