import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppState } from '../AppStateContext';

const SignOutSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAppState();
  useEffect(() => {
    // Clear authentication tokens
    logout();
    if (location.state) {
      window.history.replaceState({}, '');
    }

    // Redirect to home page after 3 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <h2 style={{color: 'white'}}>Sign Out Successful</h2>
      <p style={{color: 'white'}}>You have been successfully signed out. Redirecting to home page...</p>
    </div>
  );
};

export default SignOutSuccess;
