import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import { styles } from "../../styles/styles.js";
import { useAppState } from "../../AppStateContext.js";
import createScrollSnap from "scroll-snap";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={{...styles.cardbutton, width: '100%'}}
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', margin: 0 }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);

const CountdownTimer = ({ userProfile, lastUnlock, fetchUserProfile }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const timer = setInterval(async () => {
      const last = new Date(lastUnlock);
      const nextUnlock = new Date(last.getTime() + 2 * 60 * 1000);
      const now = new Date();
      const diff = nextUnlock - now;

      if (diff <= 0) {
        setTimeLeft('00:00:00');
        clearInterval(timer);
        await fetchUserProfile();
        // Force a page reload
        //window.location.reload();
      } else {
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        setTimeLeft(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastUnlock, fetchUserProfile]);

  return <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{timeLeft}</div>;
};

const Unlock = ({ fixture, onClose }) => {
  const { userProfile, fetchUserProfile } = useAppState();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const handleUnlock = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const url = process.env.REACT_APP_API_URL;
      console.log('Sending payload:', { fixture_id: fixture.id });

      await axios.post(`${url}/profile/unlock_fixture/`, 
        { fixture_id: fixture.id },
        { headers: { Authorization: `Token ${token}` }}
      );
      console.log('Unlocked fixture:', fixture.id);
      fetchUserProfile();
    } catch (error) {
      console.error('Error unlocking fixture:', error);
      // Could add error handling UI here
      fetchUserProfile();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(container, {
        snapDestinationY: "0%",
        timeout: 100,
        duration: 300,
        threshold: 0.2,
        snapStop: false,
        easing: (t) => t * (2 - t),
      });

      bind();
      return () => unbind();
    }
  }, []);

  return (
    <div ref={containerRef} style={{
      overflowY: "scroll",
      height: "100vh",
      scrollSnapType: "y mandatory",
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}>
        <section style={{ ...styles.sectionStyle, minHeight: "100vh" }}>
          {userProfile.can_unlock ? (
            <>
              <h3>Free Daily Unlock Available!</h3>
              <p>As a free user, you get one fixture unlock per day. This will give you access to all predictions and insights for this game.</p>
              <CardButton 
                title="Unlock This Game"
                onClick={handleUnlock}
              />
            </>
          ) : (
            <>
              <h3>Daily Unlock Used</h3>
              <p>You'll get another free unlock in:</p>
              <CountdownTimer lastUnlock={userProfile.last_unlock} userProfile={userProfile} fetchUserProfile={fetchUserProfile} />
              <p>Want unlimited access? Upgrade to Pro!</p>
              <CardButton 
                title="Upgrade to Pro"
                onClick={() => navigate('/upgrade')}
              />
            </>
          )}
        </section>

        <section style={{ ...styles.sectionStyle, minHeight: "100vh" }}>
          <h3>Why Upgrade to Pro?</h3>
          <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
            <li>Unlimited access to all game predictions and insights</li>
            <li>Advanced statistical models and projections</li>
            <li>Early access to new features</li>
            <li>Priority customer support</li>
            <li>No daily unlock limits</li>
            <li>Historical data and trends</li>
            <li>Custom alerts and notifications</li>
          </ul>
          <CardButton 
            title="Upgrade Now"
            onClick={() => navigate('/upgrade')}
          />
          <CardButton 
            title="Close"
            onClick={onClose}
          />
        </section>
      </div>
    </div>
  );
};

export default Unlock;