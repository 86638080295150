import React, { useState } from "react";
import Versus from "./Cards/versus.js";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";
import { useNavigate } from "react-router-dom";
const Game = ({ fixture, isAuthenticated }) => {
  const { isTutorialMode } = useAppState();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/fixture/${fixture.id}`, { state: { fixture, isTutorialMode } });
  };

  return (
    <div>
      <div>
        <Card className="main" style={styles.main}>
          <Card.Body>
            <Card.Title>
              <b>
                {" "}
                <Versus fixture={fixture} /> at{" "}
                {convertUTCDateToLocalDateTime(fixture.game_time)}
              </b>
            </Card.Title>
            <Table className="main" bordered onClick={handleClick}>
              <tbody>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Home{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.home_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.home_spread === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.home_spread} ({fixture.home_spread_price})
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.home_ml === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.home_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.over === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.over} ({fixture.over_price}){" "}
                  </td>
                </tr>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Away{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.away_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.away_spread === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.away_spread} ({fixture.away_spread_price}){" "}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.away_ml === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.away_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={
                      fixture.recent && fixture.recent.under === "True"
                        ? styles.Suggested
                        : styles.Odds
                    }
                  >
                    {" "}
                    {fixture.under} ({fixture.under_price})
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Game;
